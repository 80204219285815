import { Layout, Typography } from "antd";
import {
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

import PartnerForm from "../../components/UIElements/Form/PartnerForm";
import FooterPage from "../../components/Footer/Footer";

const { Header, Content } = Layout;
const { Title } = Typography;

const PartnerPage = () => {
  return (
    <Layout className="overflow-hidden">
      {/* Header Section */}

      <div className="relative bg-gradient-to-r from-purple-900 to-indigo-800 py-36 font-[sans-serif]">
        {/* Background Image */}
        <div className="absolute inset-0">
          <img
            src="/images/handshake.jpg"
            alt="Background Image"
            className="w-full h-full object-cover opacity-60"
          />
        </div>

        {/* Content */}
        <div className="cursor-pointer relative max-w-screen-lg mx-auto px-8 z-10 text-center text-white">
          {/* Title */}
          <h1 className="text-5xl md:text-6xl font-extrabold leading-tight mb-8">
            Partnership
          </h1>

          {/* Path (Center and Indicating "Home") */}
          {/* <div className="text-lg md:text-2xl font-semibold mb-12 bg-white text-indigo-800 py-4 px-6 rounded-full shadow-lg inline-block">
            <Link to='/' className="inline">Home</Link>  → Blogs
          </div> */}
        </div>
      </div>

      {/* Content Section */}
      <Layout className="bg-gray-900 text-white max-h-screen flex items-center justify-center">
        <Content className="max-w-7xl mx-auto p-8 lg:p-16 flex flex-col md:flex-row items-center justify-between space-y-12 md:space-y-0 md:space-x-12">
          {/* Left Side - Information */}
          <div className="w-full md:w-1/2 space-y-6 bg-gray-800 p-6 rounded-lg shadow-lg">
            <div className="text-[#007FFF] text-3xl font-bold">
              Make Partnership With Us
            </div>
            <div className="flex justify-center md:justify-start space-x-4">
              <a
                href="#"
                className="text-[#007FFF] hover:text-[#005fbd] transition duration-200 ease-in-out"
                aria-label="Twitter"
              >
                <TwitterOutlined className="text-2xl" />
              </a>
              <a
                href="#"
                className="text-[#007FFF] hover:text-[#005fbd] transition duration-200 ease-in-out"
                aria-label="Instagram"
              >
                <InstagramOutlined className="text-2xl" />
              </a>
              <a
                href="#"
                className="text-[#007FFF] hover:text-[#005fbd] transition duration-200 ease-in-out"
                aria-label="LinkedIn"
              >
                <LinkedinOutlined className="text-2xl" />
              </a>
            </div>
          </div>

          {/* Right Side - Form */}
          <div className="w-full md:w-1/2 space-y-6">
            <PartnerForm />
          </div>
        </Content>
      </Layout>

      {/* Footer Section */}
      <FooterPage />
    </Layout>
  );
};

export default PartnerPage;
