import React from "react";
import Hero from "../assets/images/Hero.jpg";
import Banner from "./PodCast/Banner";
import Podcast from "./PodCast/Podcast";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";

const HakimPodcast = () => {
  
  return (
    <div className="w-full h-screen relative">
      {/* <Banner /> Render the Latest component */}
      <div className="relative bg-gradient-to-r from-purple-900 to-indigo-800 py-36 font-[sans-serif]">
        {/* Background Image */}
        <div className="absolute inset-0">
          <img
            src="/images/podcast1.jpg"
            alt="Background Image"
            className="w-full h-full object-cover opacity-60"
          />
        </div>

        {/* Content */}
        <div className="cursor-pointer relative max-w-screen-lg mx-auto px-8 z-10 text-center text-white">
          {/* Title */}
          <h1 className="text-5xl md:text-6xl font-extrabold leading-tight mb-8">
            Hakim Podcast
          </h1>

          {/* Path (Center and Indicating "Home") */}
          {/* <div className="text-lg md:text-2xl font-semibold mb-12 bg-white text-indigo-800 py-4 px-6 rounded-full shadow-lg inline-block">
            <Link to='/' className="inline">Home</Link>  → Blogs
          </div> */}
        </div>
      </div>

      <br/>
      <Podcast />
      <Footer/>
    </div>
  );
};

export default HakimPodcast;