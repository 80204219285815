import React, { useEffect, useState } from "react";
import photo2 from "../../assets/images/photo4.jpg";
import { Layout, Menu, Card, Divider } from "antd";
import axios from "axios";

const { Header, Content, Sider } = Layout;

function Bio() {
  const [biographies, setBiographies] = useState([]);
  const [selectedBiography, setSelectedBiography] = useState(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await axios.get(
          "https://dashboard.hakimethio.et/api/biography"
        );
        setBiographies(response.data);
        console.log(response.data, 'bio data')
        setSelectedBiography(response?.data[0]); // Default to the first biography
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    };

    fetchProfiles();
  }, []);

  if (!biographies) {
    return <p>Loading...</p>;
  }

  return (
    <Layout className="min-h-screen bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600">
      <Header className="hidden lg:block bg-white text-center py-12 shadow-lg">
        <div className="text-4xl font-bold text-blue-600">
          Biography of {selectedBiography?.name}
        </div>
      </Header>

      <Layout className="flex flex-col sm:flex-row " >
        <div width={200} className="bg-gray-100 p-4">
          <div className="flex flex-col">
            <ul className="space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400">
              {biographies.map((bio, index) => (
                <li className="" key={index}>
                  <button
                    className={`block text-left px-4 py-3 text-nowrap rounded-lg w-full ${selectedBiography?.id === bio.id
                        ? "text-white bg-blue-700"
                        : "hover:text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    onClick={() => setSelectedBiography(bio)}
                  >
                    {bio.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <Layout style={{ padding: "24px" }}>
          <Content
            className="bg-white p-8 rounded-lg shadow-lg"
            style={{ margin: 0, minHeight: 280 }}
          >
            {selectedBiography && (
              <div className="flex flex-wrap md:flex-nowrap">
                <Card
                  cover={
                    <img
                      className="h-"
                      alt={selectedBiography.name}
                      src={`https://dashboard.hakimethio.et/storage/${selectedBiography.image}`}
                    />
                  }
                  className="max-w-xs rounded-lg shadow-lg border-2 border-gray-200 mb-6 md:mr-8"
                />
                <div className="flex-1">
                  <h1 className="text-4xl font-bold mb-4 text-blue-600">
                    {selectedBiography.name}, {selectedBiography.position}
                  </h1>
                  <p className="text-gray-700 mb-4">
                    {selectedBiography.detail}
                  </p>
                  <Divider />
                </div>
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Bio;
