import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// Card component to display each episode
const EpisodeCard = ({ videoId, title, description, onClick }) => (
  <Card
    hoverable
    onClick={onClick}
    className="w-full flex flex-col justify-between"
    style={{ height: "100%", backgroundColor: "#f0f0f0" }}
  >
    {/* Embed YouTube video */}
    <iframe
      className="w-full h-48 mb-4"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    ></iframe>

    {/* Title and Description */}
    <div className="flex flex-col flex-grow">
      <Link to={`/episode/${title}`} className="text-lg font-bold mb-2">
        {title}
      </Link>
      <p>{description}</p>
    </div>
  </Card>
);

const EpisodesGrid = () => {
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Function to fetch episodes from API
  const fetchEpisodes = async () => {
    try {
      const response = await axios.get("https://dashboard.hakimethio.et/api/all-podcasts"); 
      setEpisodes(response.data.data);
    } catch (error) {
      console.error("Error fetching episodes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEpisodes();
  }, []);

  // Function to navigate to the episode page
  const handleCardClick = (title) => {
    navigate(`/episode/${title}`);
  };

  return (
    <div className="bg-white py-10 px-4 sm:px-6 lg:px-8">
      {/* Page Title */}
      <div className="relative mb-8">
        <h2 className="text-center text-4xl font-bold mb-8 text-blue-600">
          Explore all episodes
        </h2>
        {/* See More Button */}
      
      </div>

      {/* Grid Container */}
      <div className="max-w-6xl mx-auto">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <Spin size="large" />
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            {episodes.map((episode, index) => (
              <Col
                key={index}
                xs={24}
                sm={12}
                md={8} // Change this to "md={8}" to create 3 columns on medium screens
                className="flex"
              >
                <EpisodeCard
                  videoId={episode.video_id}
                  title={episode.title}
                  description={episode.description}
                  onClick={() => handleCardClick(episode.title)}
                />
              </Col>
            ))}
          </Row>
        )}
      </div>
     <div>
     <Link to="/hakimPodcast">
          <Button
            className=" bg-blue-600 hover:bg-blue-700  mt-6 ml-8"
            type="primary"
            shape="round"
          >
            See More
          </Button>
        </Link>
     </div>
    </div>
  );
};

export default EpisodesGrid;
