import React from "react";
import { Layout, Typography } from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import ContactForm from "../../components/UIElements/Form/ContactForm";
import FooterPage from "../../components/Footer/Footer";

const { Header, Content } = Layout;
const { Title } = Typography;

const Contacts = () => {
  return (
    <Layout className="overflow-hidden">
      {/* Header Section */}
      <div className="relative bg-gradient-to-r from-purple-900 to-indigo-800 py-36 font-[sans-serif]">
        {/* Background Image */}
        <div className="absolute inset-0">
          <img
            src="/images/contact.jpg"
            alt="Background Image"
            className="w-full h-full object-cover opacity-60"
          />
        </div>

        {/* Content */}
        <div className="cursor-pointer relative max-w-screen-lg mx-auto px-8 z-10 text-center text-white">
          {/* Title */}
          <h1 className="text-5xl md:text-6xl font-extrabold leading-tight mb-8">
            Contact Us
          </h1>

          {/* Path (Center and Indicating "Home") */}
          {/* <div className="text-lg md:text-2xl font-semibold mb-12 bg-white text-indigo-800 py-4 px-6 rounded-full shadow-lg inline-block">
            <Link to='/' className="inline">Home</Link>  → Blogs
          </div> */}
        </div>
      </div>

      {/* Content Section */}
      <Layout className="bg-gray-900 text-white">
        <Content className="max-w-7xl mx-auto lg:p-8 flex flex-col md:flex-row items-center">
          {/* Contact Details */}
          <div className="w-full md:w-4/5 text-center md:text-left">
            <div className="text-center text-4xl font-bold mb-8 text-blue-600">
              <h2 className="">Contact us</h2>
            </div>
            <br />
            <div className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0 md:space-x-12">
              {/* Phone */}
              <div className="flex flex-col items-center md:items-start">
                <div className="bg-[#007FFF] rounded-full p-3 md:p-4 mb-4">
                  <PhoneOutlined className="text-white text-2xl md:text-3xl" />
                </div>
                <p className="text-gray-400 text-sm md:text-base">
                  +251974255555
                </p>
              </div>
              {/* Email */}
              <div className="flex flex-col items-center md:items-start">
                <div className="bg-[#007FFF] rounded-full p-3 md:p-4 mb-4">
                  <MailOutlined className="text-white text-2xl md:text-3xl" />
                </div>
                <p className="text-gray-400 text-sm md:text-base">
                  info@kassmaSolution.et
                </p>
              </div>
              {/* Address */}
              <div className="flex flex-col items-center md:items-start">
                <div className="bg-[#007FFF] rounded-full p-3 md:p-4 mb-4">
                  <EnvironmentOutlined className="text-white text-2xl md:text-3xl" />
                </div>
                <p className="text-gray-400 text-sm md:text-base">
                  Addis Abeba, Ethiopia
                </p>
              </div>
            </div>

            {/* Social Media Links */}
            <div className="text-2xl md:text-4xl font-bold text-white mt-8">
              Get in touch with us
            </div>
            <br />
            <div className="flex justify-center md:justify-start space-x-4">
              <a
                href="#"
                className="text-[#007FFF] hover:text-[#005fbd] transition duration-200 ease-in-out"
                aria-label="Twitter"
              >
                <TwitterOutlined className="text-xl md:text-2xl" />
              </a>
              <a
                href="#"
                className="text-[#007FFF] hover:text-[#005fbd] transition duration-200 ease-in-out"
                aria-label="Instagram"
              >
                <InstagramOutlined className="text-xl md:text-2xl" />
              </a>
              <a
                href="#"
                className="text-[#007FFF] hover:text-[#005fbd] transition duration-200 ease-in-out"
                aria-label="LinkedIn"
              >
                <LinkedinOutlined className="text-xl md:text-2xl" />
              </a>
            </div>
          </div>

          {/* Contact Form */}
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <ContactForm />
          </div>
        </Content>
      </Layout>

      {/* Footer Section */}
      <FooterPage />
    </Layout>
  );
};

export default Contacts;
