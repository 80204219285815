import React, { useState, useEffect } from 'react';
import { ClockCircleOutlined, UserOutlined, FolderOpenOutlined, CommentOutlined } from '@ant-design/icons';
import { Typography, Space, Layout } from 'antd';
import FooterPage from '../../components/Footer/Footer';
import { GetScholars } from '../../api/scholars'; // Adjust the import path to your actual API call
import axios from 'axios';

const { Title, Paragraph, Text } = Typography;
const { Header } = Layout;

const ScholarshipPost = ({ scholarship }) => (
  <div className="bg-gray-800 p-6 rounded-md shadow-lg">
    <img
      src={`https://dashboard.hakimethio.et/storage/${scholarship.image}`}
      alt={scholarship.title}
      className="w-full h-auto rounded-md mb-4"
    />

    <Title level={3}>
      <strong className="text-white mb-2">{scholarship.title}</strong>
    </Title>
    <Space className="mb-4 flex-wrap">
      <Text className="text-white flex items-center">
        <UserOutlined className="mr-1" /> {scholarship.author}
      </Text>
      <Text className="text-white flex items-center">
        <ClockCircleOutlined className="mr-1" /> {scholarship.date}
      </Text>
      <Text className="text-white flex items-center">
        <FolderOpenOutlined className="mr-1" /> {scholarship.category}
      </Text>
      <Text className="text-white flex items-center">
        <CommentOutlined className="mr-1" /> {scholarship.comments}
      </Text>
    </Space>
    <Paragraph className="text-gray-300 mb-4">
      {scholarship.title}...
      <a href={scholarship.link} className="text-blue-400 hover:text-blue-600"> Continue Reading</a>
    </Paragraph>
  </div>
);

const ScholarshipGallery = () => {
  const [scholarships, setScholarships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchScholars = async () => {
      try {
        const response = await axios.get("https://dashboard.hakimethio.et/api/scholarship");
        const data = response.data
        //  console.log(data.data,'here is sholarship data')
        const formattedData = data.map(scholarship => ({
          id: scholarship.id,
          title: scholarship.title,
          author: scholarship.author || 'Unknown',
          date: scholarship.date || 'N/A',
          category: scholarship.category || 'General',
          comments: scholarship.comments || 0,
          image: scholarship.image || 'https://via.placeholder.com/600x400',
          link: scholarship.link || '#',
        }));
        setScholarships(formattedData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchScholars();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="relative bg-gradient-to-r from-purple-900 to-indigo-800 py-36 font-[sans-serif]">
        {/* Background Image */}
        <div className="absolute inset-0">
          <img
            src="/images/scholarship1.jpg"
            alt="Background Image"
            className="w-full h-full object-cover opacity-60"
          />
        </div>

        {/* Content */}
        <div className="cursor-pointer relative max-w-screen-lg mx-auto px-8 z-10 text-center text-white">
          {/* Title */}
          <h1 className="text-5xl md:text-6xl font-extrabold leading-tight mb-8">
            Scholarships
          </h1>

          {/* Path (Center and Indicating "Home") */}
          {/* <div className="text-lg md:text-2xl font-semibold mb-12 bg-white text-indigo-800 py-4 px-6 rounded-full shadow-lg inline-block">
            <Link to='/' className="inline">Home</Link>  → Blogs
          </div> */}
        </div>
      </div>
      {scholarships && scholarships.length > 0 ? (
        <div className="min-h-screen bg-gray-900 text-white p-4 md:p-8 lg:p-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {scholarships.map((scholarship) => (
              <ScholarshipPost key={scholarship.id} scholarship={scholarship} />
            ))}
          </div>
        </div>
      ) : (
        <div className=" bg-gray-900 text-white py-10 flex items-center justify-center">
          <p className="text-xl">No data available</p>
        </div>
      )}

      <FooterPage />
    </div>
  );
};

export default ScholarshipGallery;
